<template>
  <div id="app">
    <div ref="dot" class="nav-dot"></div>
    <div id="nav">
      <div class="nav-bar nar-bar__top">
        <router-link class="nav-item home-link" to="/">
          Home
        </router-link>
      
        <router-link class="nav-item projects-link" to="/projects">
          Projects
        </router-link>
      </div>

      <div class="nav-bar nav-bar__bottom">
        <router-link class="nav-item about-link" to="/about">
          About
        </router-link>
  
        <router-link class="nav-item socials-link" to="/socials">
          Socials
        </router-link>
      </div>
    </div>
      <router-view 
        @addAnimation="addAnimation"
        @removeAnimation="removeAnimation"
        @positionDot="updateDotPosition"
      />
  </div>
</template>

<script>

  export default {
    name: 'App',
    data () {
      return {
        deviceLandscape: false
      }
    },
    created() {
      document.title = "Matt | Full Stack Developer"
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    mounted() {
      window.addEventListener(
        "orientationchange",
        this.handleOrientationChange
      );
    },
    methods: {
      addAnimation: function (animateClass) {
        this.$refs['dot'].classList.add(animateClass)
      },
      removeAnimation: function (aniamteClasses) {
        this.$refs['dot'].classList.remove(...aniamteClasses)
      },
      updateDotPosition: function (positions) {
        for (const cssAttr in positions) {
          this.$refs['dot'].style.setProperty(`--var-pos-${cssAttr}`, `${positions[cssAttr]}`)
        }
      }
    },
  }
</script>

<style lang="scss">
  @import './scss/_nav.scss';
  @import './scss/_global.scss';
</style>
