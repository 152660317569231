import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
  // from.name.toLowerCase().trim() + '-dot';
  // to.name.toLowerCase().trim() + '-dot'
  // console.log(this.$refs)
//   console.log(to)
//   console.log(from)
//   next()
// })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
